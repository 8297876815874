import { graphql, StaticQuery } from "gatsby";
import React from "react";
import BalancingCard from "./BalancingCard";

const query = graphql`
  query {
    years(save_date: { eq: true }) {
      year
      color
      dates {
        begin
        end
      }
      planned_schedule {
        date
        title
        description
      }
    }
  }
`;

export default class SaveDate extends React.Component {
  render() {
    return (
      <StaticQuery
        query={query}
        render={(data) => {
          const year = data.years;
          return (
            <BalancingCard>
              <div
                className="flex md:flex-row flex-col gap-8 justify-evenly items-center text-white rounded-xl p-8"
                style={{
                  background: year.color,
                }}
              >
                <div className="flex flex-col gap-8 md:items-end items-start w-full md:text-left">
                  <div>
                    <p className="text-2xl font-bold">Save the date!</p>
                    <h1 className="text-5xl font-black">
                      Justitia {year.year}
                    </h1>
                  </div>
                  <p>
                    Staan deze data al in je agenda? Dit wil je niet missen!
                  </p>
                  {/* <div>
                    <p>Wij zien jullie graag op 12 mei bij</p>
                    <p className="text-lg font-bold">LIEF Amsterdam!</p>
                  </div>
                  <a href="https://ticket.andgage.io/?ticketTypeIdentifier=44493982631c675d313ac4534a8b196aa2f16d90a1917271b577388ab0f8913e">
                    <button
                      className="bg-white font-bold py-3 px-6 rounded-xl text-lg transition-opacity hover:opacity-80 activw:opacity-50"
                      style={{ color: year.color }}
                    >
                      Koop je ticket hier
                    </button>
                  </a> */}
                </div>
                <div className="flex flex-col gap-4 w-full">
                  {year.planned_schedule.map((item: any) => (
                    <div
                      className="p-4 bg-white bg-opacity-30 rounded-lg"
                      key={item.title + item.date}
                    >
                      <p className="font-bold text-opacity-60">
                        {new Date(item.date).toLocaleDateString("nl", {
                          timeZone: "UTC",
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </p>
                      <h3 className="text-xl font-bold">{item.title}</h3>
                      <p className="text-lg">{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </BalancingCard>
          );
        }}
      />
    );
  }
}
