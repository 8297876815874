import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import ContentHeader from "../components/ContentHeader";
import Link from "../components/Link";
import { ManagementCard } from "../components/ManagementCard";
import PhotosCard from "../components/PhotosCard";

import SaveDate from "../components/SaveDate";
import SponsorTicker from "../components/SponsorTicker";

export const query = graphql`
  query {
    currentYear {
      year
      color
      colored_banner
      video
      save_date
      theme
      photos_published
      logo {
        childImageSharp {
          gatsbyImageData(
            width: 800
            height: 800
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            transformOptions: { fit: CONTAIN }
            backgroundColor: "transparent"
          )
        }
      }
      photos(limit: 24) {
        childImageSharp {
          gatsbyImageData(width: 40, height: 40, placeholder: BLURRED)
        }
      }
      banner {
        childImageSharp {
          gatsbyImageData(
            height: 400
            width: 1150
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      people {
        name {
          full
        }
        picture {
          childImageSharp {
            gatsbyImageData(
              width: 80
              height: 80
              placeholder: BLURRED
              transformOptions: { fit: COVER }
            )
          }
        }
      }
    }
    previousYear {
      year
      photos_published
      color
      photos(limit: 24) {
        childImageSharp {
          gatsbyImageData(width: 40, height: 40, placeholder: BLURRED)
        }
      }
    }
  }
`;

export interface IndexPageProps {
  data: {
    currentYear: any;
    previousYear: any;
    years: any;
    file: any;
  };
}

function WaitList() {
  return (
    <div className="bg-primary bg-opacity-10 p-8 rounded-xl shadow-sm">
      <h2 className="text-2xl font-bold text-primary">
        Inschrijven wachtlijst Seminar
      </h2>
      <p className="py-6">
        Het Seminar zit vol maar klik op de knop hieronder om je in te schrijven
        voor de wachtlijst. Mocht er een plek vrij komen nemen wij per e-mail
        contact op met de degene die bovenaan de lijst staat.
      </p>
      <a href="https://ticket.andgage.io/?ticketTypeIdentifier=0b43f1f6a9f27c9b7825785edc4ee5a01ac6cf98c61aa4496989c0d1256c8337">
        <div
          className="inline-flex flex-row items-center group gap-1 cursor-pointer hover:opacity-100 active:opacity-20 transition-all rounded-lg px-4 py-2 text-white"
          style={{
            background: "#1f0c35",
          }}
        >
          <p className="uppercase font-mediu group-hover:translate-x-1 transition-transform">
            Inschrijven wachtlijst
          </p>
          <span className="material-symbols-rounded text-2xl group-hover:translate-x-2 transition-transform">
            arrow_forward
          </span>
        </div>
      </a>
    </div>
  );
}

function TicketsCard() {
  return (
    <div className="bg-primary bg-opacity-10 p-8 rounded-xl shadow-sm">
      <h2 className="text-2xl font-bold text-primary">Tickets Eindfeest</h2>
      <p className="py-6">
        Koop nu je tickets voor het Eindfeest via de volgende link:
      </p>
      <a href="https://weeztix.shop/6q5c6387">
        <div
          className="inline-flex flex-row items-center group gap-1 cursor-pointer hover:opacity-100 active:opacity-20 transition-all rounded-lg px-4 py-2 text-white"
          style={{
            background: "#1f0c35",
          }}
        >
          <p className="uppercase font-mediu group-hover:translate-x-1 transition-transform">
            Koop tickets
          </p>
          <span className="material-symbols-rounded text-2xl group-hover:translate-x-2 transition-transform">
            arrow_forward
          </span>
        </div>
      </a>
    </div>
  );
}

export default class IndexPage extends React.Component<IndexPageProps> {
  render() {
    const { currentYear, previousYear } = this.props.data;
    const banner = getImage(currentYear.banner);
    return (
      <div>
        <ContentHeader
          wordBanner={["Justitia 2025 Incoming"]}
          banner={banner}
          color={currentYear.color}
        />
        <div className="flex flex-col items-center pt-20 pb-16 gap-16">
          <div className="w-full max-w-screen-md">
            <SponsorTicker />
          </div>
          <div className="flex flex-col gap-16 max-w-screen-md px-4">
            {currentYear.save_date === true && <SaveDate />}
            <TicketsCard />
            <div className="flex flex-col gap-4">
              <div className="flex sm:flex-row flex-col gap-4 justify-center">
                <div className="w-full">
                  <Link
                    to={`/${currentYear.year}`}
                    className="p-8 rounded-xl overflow-hidden relative flex flex-col items-center justify-center hover:opacity-80 active:opacity-50 transition-opacity cursor-pointer corner-fix"
                    style={{
                      backgroundColor: currentYear.color,
                    }}
                  >
                    <div className="relative z-20 text-white">
                      <h3 className=" text-3xl font-black">
                        Justitia {currentYear.year}
                      </h3>
                      <p className="text-sm">Klik hier voor meer informatie</p>
                    </div>
                    <div
                      className={`absolute top-0 left-0 w-full h-full opacity-20`}
                    >
                      {currentYear.banner?.childImageSharp?.gatsbyImageData && (
                        <GatsbyImage
                          image={
                            currentYear.banner.childImageSharp.gatsbyImageData
                          }
                          alt={""}
                        />
                      )}
                    </div>
                  </Link>
                </div>
                <div className="w-full">
                  <Link
                    to="/archief"
                    className="p-8 rounded-xl overflow-hidden relative flex flex-col items-center justify-center hover:opacity-80 active:opacity-50 transition-opacity cursor-pointer text-white bg-primary"
                  >
                    <h3 className="text-3xl font-black relative z-10">
                      Archief
                    </h3>
                    <p className="text-sm relative z-10">
                      Bekijk alle edities van Justitia
                    </p>
                  </Link>
                </div>
              </div>

              {previousYear?.photos_published == true &&
                currentYear.photos_published == false && (
                  <PhotosCard
                    photos={previousYear.photos}
                    year={previousYear.year}
                    color={previousYear.color}
                  />
                )}
              {currentYear.photos_published && (
                <PhotosCard
                  photos={currentYear.photos}
                  year={currentYear.year}
                  color={currentYear.color}
                />
              )}
              <ManagementCard
                year={currentYear.year}
                people={currentYear.people}
                color={currentYear.color}
              />
              {currentYear.video && (
                <iframe
                  src={currentYear.video}
                  width="100%"
                  allow="autoplay; fullscreen; picture-in-picture"
                  className="w-full aspect-video rounded-xl overflow-hidden"
                  allowFullScreen
                  seamless
                />
              )}
            </div>
            <p className="text-lg text-primary text-opacity-80">
              Het seminar van Justitia is verplicht voor advocaat-stagiair(e)s
              in het Amsterdamse arrondissement die in september 2024 en maart
              2025 (of later) zijn gestart met de NOvA beroepsopleiding. NB:
              Advocaat-stagiair(e)s dienen zelf zorg te dragen voor de
              inschrijving, dit gebeurt niet automatisch!
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export function Head() {
  return <title>Stichting Justitia</title>;
}
